import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const RealisationCard = ({ data, cardStyle, cardClassName }) => {
  return (
    <>
      {cardStyle === "1" &&
        <div key={data.id} className={cardClassName}>
          <Link to={`/realisation/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="tm-carousel-item construction design">
              <div className="tm-project projects type-projects status-publish has-post-thumbnail hentry projects_category-construction projects_category-design">
                <div className="thumb">
                  <div className="thumb-inner">
                    <GatsbyImage
                      className="img-fullwidth wp-pot-image"
                      image={getImage(data.frontmatter.image.card)}
                      alt={data.frontmatter.image.alt}
                    />
                    <Link to={`/realisation/${data.slug}`} className="link" aria-label={data.frontmatter.image.alt}>
                      <i className="bi bi-search"></i>
                    </Link>
                  </div>
                </div>
                <div className="details">
                  {data.frontmatter.type &&
                    <ul className="cat-list">
                      <li>
                        <Link to={`/realisation/${data.slug}`} aria-label={data.frontmatter.image.alt}>
                          {data.frontmatter.type}
                        </Link>
                      </li>
                    </ul>
                  }
                  {data.frontmatter.title &&
                    <h4>
                      <Link to={`/realisation/${data.slug}`} className="title" aria-label={data.frontmatter.image.alt}>
                        {data.frontmatter.title}
                      </Link>
                    </h4>
                  }
                  <div className="excerpt"></div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      }
      {cardStyle === "2" &&
        <div key={data.id} className={cardClassName}>
          <Link to={`/realisation/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="tm-carousel-item construction design">
              <div className="tm-project projects type-projects status-publish has-post-thumbnail hentry projects_category-construction projects_category-design">
                <div className="thumb">
                  <div className="thumb-inner">
                    <GatsbyImage
                      className="img-fullwidth wp-pot-image"
                      image={getImage(data.frontmatter.image.card)}
                      alt={data.frontmatter.image.alt}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      }
    </>
  )
}

export default RealisationCard