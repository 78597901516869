import * as React from 'react'

// Components
import ContactForm from '../../components/form/Contact';

const ContactSection = ({ site, city, sectionStyle }) => {

  return (
    <>
      {sectionStyle === "1" &&
        <section id="contact" className="bg-theme-colored1">
          <div className="container">
            <div className="section-title section-typo-light">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center">
                      <div className="title-wrapper">
                        <h2 className="title">Un projet en tête ? Discutons</h2>
                        <div className="title-seperator-line"></div>
                        <div className="paragraph">
                          <p>Contactez-nous pour vos projets en plâtrerie, peinture, décoration, aménagement, ravalement de façade, isolation intérieure et extérieure à Roanne et ses alentours.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content p-50 bg-white">
              <div className="row">
                <div className="col-12">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {sectionStyle === "2" &&
        <section id="contact" className="bg-theme-colored2">
          <div className="container">
            <div className="section-title section-typo-light">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center">
                      <div className="title-wrapper">
                        <h2 className="title">Vous souhaitez écrire un article sur {site.siteMetadata.title} ?</h2>
                        <div className="title-seperator-line"></div>
                        <div className="paragraph">
                          <p>Contactez-nous si vous avez besoin de plus d'informations.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content p-50 bg-white">
              <div className="row">
                <div className="col-12">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {sectionStyle === "3" &&
        <section id="contact" className="bg-theme-colored2">
          <div className="container">
            <div className="section-title section-typo-light">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center">
                      <div className="title-wrapper">
                        <h2 className="title">Un projet en tête ?</h2>
                        <div className="title-seperator-line"></div>
                        <div className="paragraph">
                          <p>Contactez-nous si vous avez besoin de ce service pour votre projet dans la région Roannaise.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content p-50 bg-white">
              <div className="row">
                <div className="col-12">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {sectionStyle === "4" &&
        <section id="contact" className="bg-theme-colored1">
          <div className="container">
            <div className="section-title section-typo-light">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center">
                      <div className="title-wrapper">
                        <h2 className="title">Un projet en tête ?</h2>
                        <div className="title-seperator-line"></div>
                        <div className="paragraph">
                          <p>Contactez-nous pour vos projets en plâtrerie, peinture, décoration, aménagement, ravalement de façade, isolation intérieure et extérieure à Roanne et ses alentours.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content p-50 bg-white">
              <div className="row">
                <div className="col-12">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {sectionStyle === "5" &&
        <section id="contact" className="bg-theme-colored2">
          <div className="container">
            <div className="section-title section-typo-light">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center">
                      <div className="title-wrapper">
                        <h2 className="title">Un projet à {city.name} ?</h2>
                        <div className="title-seperator-line"></div>
                        <div className="paragraph">
                          <p>Contactez-nous pour vos projets en plâtrerie, peinture, décoration, aménagement, ravalement de façade, isolation intérieure et extérieure à {city.name} et ses alentours.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content p-50 bg-white">
              <div className="row">
                <div className="col-12">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {sectionStyle === "6" &&
        <section id="contact" className="bg-theme-colored2">
          <div className="container">
            <div className="section-title section-typo-light">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center">
                      <div className="title-wrapper">
                        <h2 className="title">Un projet à {city.name}  ?</h2>
                        <div className="title-seperator-line"></div>
                        <div className="paragraph">
                          <p>Contactez-nous si vous avez besoin de ce service pour votre projet à {city.name}.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content p-50 bg-white">
              <div className="row">
                <div className="col-12">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default ContactSection